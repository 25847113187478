import React from 'react'
import CTASection from '../../components/CTASection'
import Layout from '../../components/Layout'
import CaseStudyContainer from '../../components/story/CaseStudyContainer'
import FactorialBody from '../../components/story/factorial/Content'
import FactorialHeader from '../../components/story/factorial/Header'
import FactorialResults from '../../components/story/factorial/Results'
import MetaImage from '../../assets/images/story/factorial/factorial-cover.png'

const FactorialPage = () => {
  return (
    <Layout
      title="Factorial case study"
      description="Factorial CTO and Senior Engineering Director share how they're using Swarmia to increase transparency during a period of fast growth."
      metaImage={MetaImage}
    >
      <CaseStudyContainer>
        <FactorialHeader />
        <FactorialResults />
        <FactorialBody />
      </CaseStudyContainer>
      <CTASection>
        Unblock the flow. Join&nbsp;the&nbsp;high-performing teams using Swarmia
        today.
      </CTASection>
    </Layout>
  )
}
export default FactorialPage
