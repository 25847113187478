import React from 'react'
import image5 from '../../../assets/images/story/factorial/factorial-code-review.png'
import image2 from '../../../assets/images/story/factorial/factorial-daily-digest.png'
import image3 from '../../../assets/images/story/factorial/factorial-deployments.png'
import image1 from '../../../assets/images/story/factorial/factorial-pr.png'
import image4 from '../../../assets/images/story/factorial/factorial-throughput.png'
import josep from '../../../assets/images/story/factorial/josep.png'
import pau from '../../../assets/images/story/factorial/pau.png'
import { InlineQuote } from '../../blocks/InlineQuote'
import Content from '../Content'
import { ProductScreenshot } from '../ProductScreenshot'

const FactorialBody = () => {
  return (
    <Content
      sidebar={{
        company: (
          <p>
            Factorial is a SaaS company that provides intuitive HR solutions for
            small and medium businesses. Founded in 2016, the company now serves
            3,000 customers in 65+ countries.
          </p>
        ),
        href: 'https://factorialhr.com',
        location: <p>Headquartered in Barcelona, fully remote</p>,
        headcount: <p>1,100+ employees, 150+ engineers</p>,
        customers: <p>2022</p>,
      }}
      main={
        <>
          <p>
            Pau Revilla, CTO of Factorial, leads a fast-growing, globally
            distributed engineering organization. Senior Engineering Director
            Josep Jaume Rey works in the Developer Experience team and is
            responsible for analyzing engineering outcomes and improving the
            daily lives of Factorial’s developers.
          </p>
          <p>
            In 2022 alone, the Factorial engineering organization grew from 80
            to 150 software developers. When Pau and Josep started looking to
            gain visibility into the growing organization and its bottlenecks,
            it was important that the tool would support their culture of
            ownership and accountability.
          </p>
          <InlineQuote
            quote="At Factorial, each engineering team owns their projects from end-to-end, from the request for comments to pull requests and up until communication to the rest of the company. It was important for us to get quantitative information on how the teams are doing, but without adding any friction."
            name="Pau Revilla"
            title="CTO"
            photo={pau}
          />
          <p>
            Pau had previously been using Pull Panda for lightweight analytics
            on the teams’ pull request lifecycle. But after Pull Panda was
            acquired by GitHub, rebranded as Pull Reminders, and eventually
            deprecated, he felt there was a gap in the market for a tool that
            would help them eliminate bottlenecks in their delivery lifecycle.
          </p>
          <p>
            Additionally, Pau and Josep were interested in getting access to
            DORA metrics and giving both managers and individual contributors
            the insights and tools they need to make better decisions.
          </p>
          <InlineQuote
            quote="More than anything, we were looking to give managers visibility so that they could coach their people. As the teams got larger, our managers no longer knew what work was being done or where the bottlenecks were. In addition to looking at planned work, they also needed visibility into actual work. It was important to get access to that data, so the managers could offer their support at the right time."
            name="Pau Revilla"
            title="CTO"
            photo={pau}
          />

          <h2>Actionable insights for managers and individual contributors</h2>

          <p>
            Factorial’s first objective with Swarmia was to gain visibility into
            their pull request lifecycle. As with many growing teams, they
            quickly noticed that code reviews were becoming a bottleneck.
          </p>
          <ProductScreenshot src={image1} />
          <InlineQuote
            quote="Cycle time is central to every engineering team at Factorial. If we’re somehow adding friction to the development workflows, we can see our cycle times getting higher in Swarmia. This helps us quickly find and fix the underlying issues."
            name="Josep Jaume Rey"
            title="Senior Engineering Director"
            photo={josep}
          />
          <p>
            Almost 80% of Factorial’s engineering teams have subscribed to
            Swarmia’s daily digests in Slack, which summarize the team’s open
            pull requests and any exceptions to the working agreements they’ve
            adopted. Josep also keeps receiving positive feedback about
            Swarmia’s personal Slack notifications from engineers across the
            organization.
          </p>
          <InlineQuote
            quote="One of the things thin GitHub should provide, Swarmia covers very well. Swarmia’s GitHub–Slack notifications are way better than in any other tool and it’s one of the reasons our engineers really love the product."
            name="Josep Jaume Rey"
            title="Senior Engineering Director"
            photo={josep}
          />
          <ProductScreenshot src={image2} />
          <p>
            Some of the more mature teams at Factorial have also started using
            Swarmia to track DORA metrics. While a wider rollout hasn’t been
            possible yet due to the organization’s rapid growth, Josep is hoping
            to gradually roll them out in the coming months.
          </p>
          <ProductScreenshot src={image3} />
          <h2>
            Results: 2x throughput, improved code review rate, and faster merge
            time
          </h2>
          <p>
            In their first year of using Swarmia, the Factorial engineering
            organization managed to more than double their throughput, which is
            rare for a company growing as fast as Factorial has been. After all,
            it’s difficult to maintain, let alone improve, productivity while
            spending a lot of time on hiring and onboarding new teammates.
          </p>
          <ProductScreenshot src={image4} />
          <p>
            After adopting Swarmia’s GitHub notifications in Slack, Factorial’s
            overall code review rate has increased from 74% to 95%. They’ve also
            managed to drop the average time for first code review from 12 hours
            to 10 hours and reduce merge time from 24 to 15 hours, which are
            impressive results for a globally distributed team.
          </p>
          <ProductScreenshot src={image5} />
          <p>
            In addition to the concrete and measurable improvements Josep has
            seen in the engineering organization, he’s been particularly happy
            with Swarmia’s ability to deliver new features.
          </p>
          <InlineQuote
            quote="A lot of people at Factorial, including me, get a lot of value out of Swarmia. I’ve also noticed many improvements and new features in the product in the past year, and that’s been great to see."
            name="Josep Jaume Rey"
            title="Senior Engineering Director"
            photo={josep}
          />
          <p>
            Next up, Josep and Pau are focusing on improving performance,
            stability, and quality with the help of Swarmia. Josep is also
            hoping to do an organization-wide rollout of DORA metrics now that
            some teams have had a chance to try them out.
          </p>
          <InlineQuote
            quote="Swarmia gives us a better understanding of quality. And it’s not only about software quality but also about the quality of our release strategy, including how we deploy changes."
            name="Josep Jaume Rey"
            title="Senior Engineering Director"
            photo={josep}
          />
        </>
      }
    />
  )
}
export default FactorialBody
